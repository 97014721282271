import { drawPath, paths } from "./Utils";
export class SpadeDrawer {
    draw(context, particle, radius) {
        drawPath(context, radius, paths.spade);
    }
}
export class HeartDrawer {
    draw(context, particle, radius) {
        drawPath(context, radius, paths.heart);
    }
}
export class DiamondDrawer {
    draw(context, particle, radius) {
        drawPath(context, radius, paths.diamond);
    }
}
export class ClubDrawer {
    draw(context, particle, radius) {
        drawPath(context, radius, paths.club);
    }
}
